import MaintenanceBanner from "./maintenance-banner";

const Banners = () => {
  return (
    <>
      <MaintenanceBanner />
    </>
  );
};

export default Banners;
